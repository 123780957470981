import { ValidationCode, ValidationResult } from ".";

/**
 * メールアドレスをバリデーションする。
 * @param {string} email
 * @returns {ValidationResult}
 */
export function validateEmail(email) {
  const sanitized = email.trim();

  /** フォーマットが正しいこと */
  if (!/^.+@.+$/.test(sanitized))
    return ValidationResult.createInvalid(
      "フォーマットが不正です",
      ValidationCode.IllegalFormat
    );

  /** 254文字以下であること */
  if (sanitized.length > 254)
    return ValidationResult.createInvalid(
      "254文字以内である必要があります",
      ValidationCode.MoreThanMax
    );

  return ValidationResult.createValid();
}
