/**
 * バリデーション結果を表すコードを列挙したEnum
 */
export const ValidationCode = {
  EmptyNotAllowed: "empty_not_allowed",
  IllegalChars: "illegal_chars",
  IllegalFormat: "illegal_format",
  IllegalLength: "illegal_length",
  IllegalType: "illegal_type",
  IllegalNumType: "illegal_number_type",
  LessThanMin: "less_than_min",
  MoreThanMax: "more_than_max",
  NotAllowed: "not_allowed",
};

export class ValidationResult {
  /**
   * バリデーションの結果、有効であればtrue、無効であればfalse
   * @type {boolean}
   */
  isValid;

  /**
   * 有効の場合にはnull、無効の場合には無効の理由を示す文字列
   * @type {?string}
   */
  message;

  /**
   * @type {?string}
   */
  _code;

  /**
   * バリデーション結果を識別するコード（任意）
   * @type {?string} - ValidationCodeに列挙されている値
   */
  get code() {
    return this._code;
  }

  /**
   * @param {string} val - ValidationCodeに列挙されている値
   */
  set code(val) {
    this._code = val;
  }
  
  /**
   * @param {boolean} isValid
   * @param {?string} message
   * @param {?string} code
   */
  constructor(isValid, message, code) {
    this.isValid = isValid;
    this.message = message;
    this._code = code;
  }

  /**
   * `code`を上書きした後、オブジェクト自身を返す（メソッドチェーンを可能にする）
   * @param {string} code 
   * @returns {ValidationResult}
   */
  setCode(code) {
    this.code = code;
    return this;
  }

  /**
   * @returns {Error}
   */
  toError() {
    return new Error(this.message, { code: this.code });
  }
  
  /**
   * 有効なバリデーション結果を生成する
   * @param {?string} code
   * @returns {ValidationResult}
   */
  static createValid(code = null) {
    return new ValidationResult(true, null, code);
  }

  /**
   * 無効なバリデーション結果を生成する
   * @param {string} message - 無効な理由
   * @param {?string} code
   * @returns {ValidationResult}
   */
  static createInvalid(message, code = null) {
    return new ValidationResult(false, message, code);
  }
}
